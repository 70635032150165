import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import moment from "moment";
// import APIServicenew from "../utils/APIGeneralService";
// import {  getCookies, setCookies } from "../utils/Helper";
// import DynamicDataTableWithCount from "../components/dataTableWithCount";
// import { UserContext } from "../context/UserContextProvider";
import { useToasts } from "react-toast-notifications";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import APIServicenew from "../../utils/APIGeneralService";
import { getCookies , setCookies  } from "../../utils/Helper";
import DynamicDataTableWithCount from "../DynamicDataTableWithCount";
import { UserContext } from '../../context/UserContextProvider';
import usePermissionData from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Notauthorized";
import Select from 'react-select';
import {Eye } from 'react-bootstrap-icons';

const ManokamnaList = () => {
  document.title = 'Manokamana List';
  const navigate = useNavigate();
  const permissioncontext =usePermissionData("manokamanaList")
  const token = getCookies("accessToken");
  const headers = { Authorization: `Bearer ${token}` };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(40);
  const [pageIndex, setPageIndex] = useState(0);

  // filter name
  const [chadawaId, setChadawaId] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobNum, setUserMobNum] = useState("");
  const [templeName, setTempleName] = useState("");
  const [chadawaName, setChadawaName] = useState("");
  const [astroMobNum, setAstroMobNum] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const { refreshToken,menuItem } = useContext(UserContext);

  const { addToast } = useToasts();
  const { user: adminUser } = useContext(UserContext);

  // for call user
  const [showCallCm ,setShowCallCm] = useState(false)
  const [dial, setDail] = useState("Call");
  const [currentUserId ,setCurrentUserId] = useState('')
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };

  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );

  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "manokamna",
      },
      headers
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    userCall();
  };

  const [countData,setCountData]=useState()
  // for notes
  const [userForNote, setUserForNote] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState("");
  const [error, setError] = useState(false);
  const [countryList,setcountryList]=useState([])

  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };

  const handleUserNotes = async (id) => {
    const res = await APIServicesSec.get(
      `userNotes/getDetailById/${id}`,
      headers
    );

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();
        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.user_id,
        source:"Marketing-Manokamna-List",
        name:userForNote?.user_mobile,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
        id:userForNote?.id,
      };
      setLoader(true);
      const res = await APIServicesSec.post(
        `userNotes/userNoteMessage`,
        payload,
        headers
      );

      if (res && res?.data?.status === true) {
        addToast("Note added successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        // toast.error(res?.data?.message);
        addToast(res?.data?.message, {
          appearance: "denger",
          autoDismiss: true,
        });

        setLoader(false);
      }
      setLoader(false);
    }
  };


  // date filter

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  useEffect(()=>{
    let existingFilterData = {};
          const storedData = localStorage.getItem("manokamnaBookingListFilterData");
          existingFilterData = storedData ? JSON.parse(storedData) : {};
      if (Object.keys(existingFilterData).length > 0) {
          setPageIndex(existingFilterData.pageIndex || 0);
          setSkip(existingFilterData.skip || 0);
          setChadawaId(existingFilterData.chadawaId ||'')
          setUserName(existingFilterData.userName || '');
          setUserMobNum(existingFilterData.userMobNum ||'')
          setTempleName(existingFilterData.templeName ||'')
          setChadawaName(existingFilterData.chadawaName ||'')
          setAstroMobNum(existingFilterData.astroMobNum ||'')
          setCurrencyCode(existingFilterData.currencyCode ||'')
          setTransactionId(existingFilterData.transactionId ||'')
          setSource(existingFilterData.source ||'')
          setStatus(existingFilterData.status ||'')
          setRefundStatus(existingFilterData.refundStatus ||'')
          setPaymentStatus(existingFilterData.paymentStatus ||'')
          setCountry(existingFilterData.country ||'')
          if (existingFilterData.startDate && existingFilterData.endDate ) {
            setStartDate(new Date(existingFilterData.startDate) || '');
            setEndDate(new Date(existingFilterData.endDate) || '');
          }
          setSelectedRange(existingFilterData.selectedRange || '');
          setFlag(true);
          } else {
           setPageIndex(0);
           setSkip(0);
           setChadawaId('')
           setUserName('')
           setUserMobNum('')
           setTempleName('')
           setChadawaName('')
           setAstroMobNum('')
           setCurrencyCode('')
           setTransactionId('')
           setSelectedRange('')
           setSource('')
           setStatus('')
           setRefundStatus('')
           setStartDate("")
           setEndDate("")
           setPaymentStatus('')
           setCountry('')
           setFlag(true);
          }
  },[])
 

  const resetData = () => {
  setChadawaId('')
  setUserName('')
  setUserMobNum('')
  setTempleName('')
  setChadawaName('')
  setAstroMobNum('')
  setCurrencyCode('')
  setTransactionId('')
  setStartDate('')
  setEndDate('')
  setSource('')
  setStatus('')
  setRefundStatus('')
  setSelectedRange('')
  setPaymentStatus('')
  setPageIndex(0);
  setCountry('')
  setFlag(true);
  localStorage.setItem("manokamnaBookingListFilterData","");

  };

  useEffect(() => {
    handleCountData()
    fetchCountryList()
    if (flag === true) {
     getAllData(skip, limit, pageIndex);
    }
  }, [flag]);




  const fetchCountryList=async()=>{
    let response = await APIServiceschatbot.get(`country/getPhoneCode`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data)
      }
    }
  }

  const [country, setCountry] = useState('');
  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0)
 };

 const options = countryList.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
  value: item.name,
  label: item.name,
}));

  const handleSearch = () => {
    if (
        chadawaId || userName || userMobNum || templeName || chadawaName ||  currencyCode ||
         transactionId || source || status || refundStatus || paymentStatus ||
        startDate ||
        endDate || country || selectedRange
    ) {
      getAllData(
        skip,
        limit,
        0,
        chadawaId , userName , userMobNum , templeName , chadawaName ,  currencyCode ,
         transactionId , source , status , refundStatus , paymentStatus ,
            startDate ,
            endDate, country
      );

      const formatDateForStorage = (date) => {
        return date ? new Date(date).toISOString().split('T')[0] : ''; // Converts to YYYY-MM-DD
      };
        const updatedFilterData = {
          userName,
          startDate: formatDateForStorage(startDate),
          endDate: formatDateForStorage(endDate),
          limit,
          skip,
          pageIndex,
          chadawaId,
          userMobNum,
          templeName,
          chadawaName,
          selectedRange,
          currencyCode,
          transactionId,
          source,
          status,
          refundStatus,
          paymentStatus,
          country
        }
      localStorage.setItem("manokamnaBookingListFilterData", JSON.stringify(updatedFilterData));
    }
  };

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Temple Name	",
      Cell: (e) => {
        return (
          <>
            <span>{e.row.original.temple_name}</span>
            <br />
          </>
        );
      },
    },
    {
      Header: "Manokamana Id",
      Cell: (e) => {
        return <span>{e.row.original.id ?? "_"}</span>;
      },
    },
    {
      Header: "User Name",
      Cell: (e) => {
        return (
          <>
            <span>{e.row.original.user_name}</span>
            <br />
          </>
        );
      },
    },
    {
      Header: "User Mobile Number",
      Cell: (e) => {
        return (<>
                  <span> {e.row.original.user_mobile ?? "_"}</span>
                  {/* <br/>
                  <button
                      onClick={() => {
                        setShowCallCm(true);
                        setCurrentUserId(e.row.original.user_id);
                      }}
                      className="mt-2  call_btn btn btn-secondary"
                    >
                     Call to User
                  </button> */}
               </>
                
        ) 
      },
    },
    {
      Header: "Manokamana Name",
      Cell: (e) => {
        return <span>{e.row.original.chadawa_name ?? "_"}</span>;
      },
    },

    {
      Header: "Amount",
      Cell: (e) => {
        return <span> {e.row.original.amount === "0" ? "Free" : (e.row.original.currency_symbol + e.row.original.amount)}</span>;
      },
    },
    {
      Header: "Transaction Id",
      Cell: (e) => {
        return (
          <span>{e.row.original.transaction_id}</span>
        );
      },
    },
    {
      Header: 'Language',
      Cell: e => {
        return <span>{e.row.original?.language ?? '_'}</span>;
      },
    },
    {
      Header: 'Country Name',
      Cell: e => {
        return <span>{e.row.original?.country ?? '_'}</span>;
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return <span>{e.row.original.source}</span>;
      },
    },

    {
      Header: "Booking Status(refund)",
      Cell: (e) => {
        return (
          <>
          {/* {e.row.original?.refund_status === "pending" && e.row.original.status === "cancelled" ? (
            <div className="d-inline-flex flex-column gy-2">
              <span className="text-danger fw-bold mb-1 font_14">{e.row.original.status ?? "_"}</span>
              <span className="text-pending fw-bold font_14">pending</span>
            </div>
          ) : e.row.original?.refund_status === "completed" && e.row.original.status === "cancelled" ? (
            <div className="d-inline-flex flex-column gy-2">
              <span className="text-danger fw-bold mb-1 font_14">{e.row.original.status ?? "_"}</span>
              <span className="text-success fw-bold font_14">Completed</span>
            </div> */}
            {e.row.original?.refund_status === "pending" ? (
              <>
                <div className="d-inline-flex flex-column gy-2">
                  <span className="text-danger fw-bold font_14 mb-1">{e.row.original.status ?? "_"}</span>
                  <span className="text-pending fw-bold font_14">Refunded</span>
                </div>
              </>
          ) : (
            <span
            className={e.row.original.status === "completed" ? "text-success fw-bold font_14" : e.row.original.status === "processing" ? "text-warning font_14 fw-bold" : "text-danger fw-bold font_14"}
            >
              {e.row.original.status ?? "_"}</span>
          )}
        </>
        );
      },
    },

    {
      Header: "Created Date	",
      Cell: (e) => {
        return (
          <span>
            {/* {moment(e.row.original.created_at).format("DD/MM/YYYY h:mm:ss A")} */}
            {moment.utc(e.row.original.created_at).local().format("DD MMM, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      Header: 'Notes',
      Cell: e => {
        return (
          <h5>
            <input
              type='button'
              className={!permissioncontext.add?"Et-btn notes-btn disabled-btn":'Et-btn notes-btn '}
              disabled={!permissioncontext.add}
              value='Notes'
              onClick={() => {
                setUserForNote(e.row.original);
                handleUserNotes(e.row.original.user_id);
              }}
            />
             
          </h5>
        );
      },
    },
    {
      Header: "Action",
      Cell: (e) => {
        return (
          <>
            <span><Link to={`/manokamnaDetails?manokamnaId=${e.row.original?.id}`}
                        state={{ permissioncontext: permissioncontext }}
                        >
                     <Eye style={{ fontSize: '1.5rem', color: 'green' }} />
                  </Link></span>
          </>
        );
      },
    },
  ]);


  const logout = () => {
    refreshToken(null);
    setCookies("accessToken", null);
    navigate("/");
  };


  const handleCountData = async () => {
    const res = await APIServicesSec.get(`lead/getmanokamnacount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.data);
      } else {
        console.log("error");
      }
    }
  };

  const getAllData = useCallback(
    async (skip, limit, index) => {
      try {
        let countryId = country?.value === undefined ? '' : country?.value;
        let created_at = '';
        if(startDate && endDate){
            let sDate = moment(startDate).format('MM/DD/YYYY');
            let eDate = moment(endDate).format('MM/DD/YYYY');
            created_at = sDate+" - "+eDate;
        }
       let page  = skip/limit+1;
        const response = await APIServicesSec.get(
          `lead/manokamanaBookingList/${page}/${limit}/?userName=${userName}&source=${source}&country=${countryId}&templeName=${templeName}&currencyCode=${currencyCode}&transactionId=${transactionId}&createdAt=${created_at}&userMobile=${userMobNum}&refundStatus=${refundStatus}&amount=${paymentStatus}&status=${status}&manokamanaId=${chadawaId}&chadawaName=${chadawaName}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      // setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      chadawaId , userName , userMobNum , templeName , chadawaName ,  currencyCode , 
      transactionId , source , status , refundStatus , paymentStatus,startDate ,
      endDate, country
    ]
  );

  const [allBookingDownload,setAllBookingDownload] = useState([])

  const handleDownload = (data) => {
    
    for (let puja of data) {
      if (puja.temple_name) {
        puja['Temple Name '] = puja?.temple_name;
      }
      if (puja.id) {
        puja['Manokamana Id'] = puja.id;
      }
      if (puja.user_name) {
        puja['User Name'] = puja.user_name;
      }
      if (puja.user_mobile) {
        puja['User Number'] = puja.user_mobile;
      }
      if (puja.chadawa_name) {
        puja['Manokamana Name'] = puja?.chadawa_name ?? '';
       }
      if (puja.amount) {
        puja['Amount'] = puja.amount === "0" ? "Free" : puja.currency_symbol+puja.amount;
      }
      if (puja.transaction_id) {
        puja['Transaction Id'] = puja?.transaction_id ?? '';
      }
      if (puja.source) {
        puja['Source'] = puja.source;
      }
      
      if (puja.status) {
        puja['Booking Status'] = puja.status;
      }

      if (puja.created_at) {
        // puja['Created Date '] = moment(puja?.created_at).format('lll');
        puja['Created Date '] = moment.utc(puja?.created_at).local().format("DD MMM, YYYY hh:mm A")
      }
    
      
      delete puja.currency_symbol;
      delete puja.country;
      delete puja.temple_name;
      delete puja.id;
      delete puja.user_name;
      delete puja.user_mobile;
      delete puja?.chadawa_name;
      delete puja.amount;
      delete puja.transaction_id;
      delete puja.source;
      delete puja.payment_status;
      delete puja.refund_status;
      delete puja.created_at;
      delete puja.status;
   
    }

    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveBlob(csvData, "UserList.csv");
    else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.download = "ManokamnaBooking.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };


const fetchedAllBookingDownload = async () => {
  let created_at = '';
        if(startDate && endDate){
            let sDate = moment(startDate).format('MM/DD/YYYY');
            let eDate = moment(endDate).format('MM/DD/YYYY');
            created_at = sDate+" - "+eDate;
        }

        const res = await APIServicesSec.get(
          `lead/manokamanaBookingList?userName=${userName}&source=${source}&templeName=${templeName}&currencyCode=${currencyCode}&transactionId=${transactionId}&createdAt=${created_at}&userMobile=${userMobNum}&refundStatus=${refundStatus}&amount=${paymentStatus}&status=${status}&manokamanaId=${chadawaId}&chadawaName=${chadawaName}`,
          headers
        );
      
  if (res && res.status === 200) {
    let resData = res.data;
    if (resData && resData.status) {
      setAllBookingDownload(resData?.Listdata);
      handleDownload(resData?.Listdata);
    }
  }
};


  return (
    <>
    {permissioncontext.view?
      <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">
            <div className="counter-wrapper d-flex align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                Total Bookings <small>{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Manokamana <small>{countData?.todaysPaidManokamana}/{countData?.todaysManokamana}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Manokamana <small>{countData?.thisWeekPaidManokamana}/{countData?.thisWeekManokamana}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Manokamana <small>{countData?.thisMonthPaidManokamana}/{countData?.thisMonthManokamana}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Paid Manokamana <small>{countData?.paidManokamana}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                Free Manokamana <small>{countData?.freeManokamana}</small>
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* <Container className="mb-2 "> */}
      <div class="chat_window">
        <Row className="mlist  my-3 br-6 p-20">
          <div class="chat_search_box user-search">
            <div className="chat-search-field">
              <Row>
                <Form>
                  <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">Manokamana Id</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="call number"
                        type="text"
                        placeholder="Manokamana Id"
                        value={chadawaId}
                        onChange={(e) => {
                          setChadawaId(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">User Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Name"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                        User Mobile Number
                      </Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="number"
                        type="number"
                        placeholder="Search Mobile Number"
                        value={userMobNum}
                        onChange={(e) => {
                          setUserMobNum(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">Temple Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Name"
                        value={templeName}
                        onChange={(e) => {
                          setTempleName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">Manokamana Name</Form.Label>

                      <Form.Control
                        className=" mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Manokamana Name"
                        value={chadawaName}
                        onChange={(e) => {
                          setChadawaName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                                <Form.Label class='label-f-w-cd'>
                                  Country
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={country}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Country...'
                                    isClearable
                                  />
                  </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                        Currency Code
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={currencyCode}
                        onChange={(e) => {
                          setCurrencyCode(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=""> Select</option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                        Transaction Id
                      </Form.Label>

                      <Form.Control
                        className="mt-2 form-ch"
                        name="name"
                        type="text"
                        placeholder="Search By Transaction Id"
                        value={transactionId}
                        onChange={(e) => {
                          setTransactionId(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    

                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="align-items-end mb-1"
                    controlId=""
                  >

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                      Refund Status
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={refundStatus}
                        onChange={(e) => {
                            setRefundStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option defaultValue="">Select Refund Status</option>
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                      </Form.Select>
                    </Col>

                    <Col md={6} lg={3} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                        Select By Booking Date
                      </Form.Label>
                      <div className="date-range-wrapper mt-2">
                        <div className='select-icon-wrapper'>
                          <select
                            className="select-range"
                            value={selectedRange}
                            onChange={(e) => handleDateRange(e.target.value)}
                          >
                            <option value="">Select Date Range</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="thisWeek">This Week</option>
                            <option value="last7Days">Last 7 Days</option>
                            <option value="thisMonth">This Month</option>
                            <option value="lastMonth">Last Month</option>
                            <option value="customDate">Custom Date</option>
                          </select>
                          <img src='/images/down-arrow.png' className='select-icons'/>
                        </div>

                        {selectedRange === "customDate" && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            // isClearable={true}
                            placeholderText="Select custom date range"
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                      </div>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">Source</Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={source}
                        onChange={(e) => {
                          setSource(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option defaultValue=""> All</option>
                        <option value="web">Web</option>
                        <option value="app">App</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className="mb-3 mb-lg-0">
                      <Form.Label class="label-f-w-cd">
                        Booking Status
                      </Form.Label>
                      <Form.Select
                        aria-label="label-selected-menu"
                        className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                        value={paymentStatus}
                        onChange={(e) => {
                            setPaymentStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option defaultValue="">Booking Type</option>
                        <option value="free">Free</option>
                        <option value="paid">Paid</option>
                      </Form.Select>
                    </Col>

                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="align-items-end mb-1 mt-3"
                        controlId=""
                      >
                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Select Status
                          </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option defaultValue=""> All</option>
                            <option value="processing">Processing</option>
                            <option value="completed">Completed</option>
                            <option value="cancelled">Cancelled</option>
                          </Form.Select>
                        </Col>
                        <Col lg={6} md={6} className="mb-3 mb-lg-0 mt-3">
                          <Button
                            variant="secondary"
                            className="as_btn ms-0"
                            onClick={() => {
                              handleSearch();
                              setSkip(0);
                            }}
                          >
                            Filter
                          </Button>
                          <Button
                            variant="secondary"
                            className="as_btn reset-btn"
                            onClick={() => {
                              resetData();
                            }}
                          >
                            Reset
                          </Button>
                          {/* <Button
                            className="as_btn download-btn"
                            variant="primary"
                            onClick={() => {
                              fetchedAllBookingDownload();
                            }}
                          >
                            Download Booking
                          </Button> */}
                          {adminUser?.adminType === "super_admin" ?
                      (
                          <Button
                            className='as_btn ms-2 download-btn'
                            variant='primary'
                            onClick={() => {
                              fetchedAllBookingDownload();
                            }}
                          >
                            Download Booking
                          </Button>
                      )
                    
                    : ""}
                        </Col>

                  </Form.Group>

                </Form>
              </Row>
            </div>
          </div>
        </Row>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            // notShowPage ={true}
            fetchData={getAllData}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className="no_records">Records not Available</h2>
        )}
      </div>
       {/* user call confirmation */}
       <Modal
       centered
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for user note */}
      <Modal
      centered
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3">
              {" "}
              Name :- {userForNote?.user_name
                ? userForNote?.user_name
                : ""}{" "}
            </span>
            <span>
              {" "}
              Contact Number :-{" "}
              {userForNote?.user_mobile
                ? userForNote?.user_mobile
                : ""}{" "}
            </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
          <Row>
          
            </Row> 
          
          
          {notesData && notesData?.notes?.length > 0 ? (
            <>
              { notesData?.notes && notesData?.notes?.length>0 &&notesData?.notes?.map((item, index) => (
                <>
                <Row>
                          {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }

{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                    <Link to={`/shared-puja?pujaId=${item?.sourceid}`} state={{permissioncontext:permissioncontext}}>
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                    </Link>
                </span>
            </Col>
        )
    ):
    item.source === 'Marketing-Reports-Inquiry'
    || item.source === "Leads-Reports-Inquiry" ?
    <Col xs={7}>
          <span className='aa'>Reports Name :- </span>
          <span className='item-msg'>
            
              {item?.sourcename?.length <= 50
                ? item?.sourcename
                : `${item?.sourcename?.substring(0, 40)}...`}
          </span>
        </Col> : null
)}

                </Row>
                
                  <Row className="notes-item">
                    <Col xs={9}>
                      <span className="item-name aa">
                              Note :- 
                      </span>
                      <span className="item-msg"> {item?.message}</span>
                    </Col>
                   
                   
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                          <span className="text-primary item-name aa">
                            {item?.responderName || item?.adminName}
                          </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("DD MMM, YYYY hh:mm A")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>: <Notauthorized />}
    </>

    
  );
};

export default ManokamnaList;
